<template>
    <ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
        <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>

        <v-form @submit.prevent="handleSubmit(save)">
            <v-row>
                <v-col cols="12" md="3">
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                        <v-text-field outlined dense :error-messages="errors" v-model="form.Name" label="Nombre">
                        </v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3">
                    <ValidationProvider name="State" rules="required" v-slot="{ errors }"> 
                        <v-select outlined dense  :error-messages="errors" :items="states" item-text="name" item-value="value" attach
                            label="Estado" v-model="form.State"></v-select>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3">
                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                        <v-select outlined dense  :error-messages="errors" :items="Type" item-text="name" item-value="value" attach
                            label="Tipo" v-model="form.Type"></v-select>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3" v-if="form.Body">
                    <v-btn @click="open" class="btn-add">{{
                    !isEmpty(form.Body)
                    ? editMood
                    ? "Modificar plantilla"
                    : "Modificar plantilla"
                    : "Agregar plantilla"
                    }}</v-btn>
                </v-col>
                <v-col cols="12" md="3" v-else>
                    <v-btn @click="open" class="btn-add">Agregar plantilla</v-btn>
                </v-col>
               
                <v-col class="mt-2" cols="12" sm="12" md="12">
                    
                        <v-btn class="btnclean mr-1" @click="clean">Limpiar</v-btn>
                        <v-btn class="btnsave" type="submit" :loading="loading_btn"
                            :disabled="invalid || isEmpty(form.Body) || loading_btn">Guardar </v-btn>
                    
                </v-col>
            </v-row>

            <v-dialog v-model="dialog" scrollable max-width="auto">
                <v-card height="inherit">
                    <v-card-title class="text-h5"> {{ formTitle }}</v-card-title>
                    <v-card-text v-if="form.Body">
                        <v-row>
                            <v-col cols="12" md="12">
                                <EmailEditor :appearance="appearance" :min-height="minHeight" :project-id="projectId"
                                    :display-mode="displayMode" :locale="locale" :tools="tools" :options="options"
                                    ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error darken-1" text @click="close"> Cerrar </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success darken-1" text @click="exportData">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </ValidationObserver>
</template>
  
<script>
import _ from "lodash";
import { EmailEditor } from "vue-email-editor";
import Alerts from "../../../components/Alerts.vue";
export default {
    name: "ManageHeaderFooter",
    props: ["HF", "win", "editMood"],
    components: { EmailEditor,alerts: Alerts },
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            form: {},
            dialog: false,
            //   editMood: false,
            loading_btn: false,
            states: [
                { name: "Activo", value: "1" },
                { name: "Inactivo", value: "0" },
            ],
            Type: [
                { name: "Encabezado", value: "Header" },
                { name: "Pie", value: "Footer" },
                { name: "Encabezado Prescripción", value: "HeaderPrescription" },
                { name: "Pie Prescripción", value: "FooterPrescription" },
            ],
            Body: {
                html: "",
                design: "",
            },
            defaultTemp: {
                html: "",
                design: "",
            },
            minHeight: "1000px",
            locale: "en",
            projectId: 0, // replace with your project id
            displayMode: "email",
            tools: {
                // disable image tool
                image: {
                    enabled: false,
                },
            },
            options: {},
            appearance: {
                // theme: 'dark',
                panels: {
                    tools: {
                        dock: "right",
                    },
                },
            },
            isHeader: [],
            isFooter: [],
            isHeaderPrescription: [],
            isFooterPrescription: [],
        };
    },
    watch: {
        dialog(val) {
            val || this.close;
        },
    },
    computed: {
        formTitle() {
            return this.editMood ? "Change Current Template" : "Add New Template";
        },
    },
    mounted() {
        this.getHeaderFooter();
        this.fetchCurrentTemp();
    },
    methods: {
        closeAlert() {
      this.alert.show = false;
            
      if(this.alert.options == "reloadandredirect") this.$emit("reloadData");
     
    },
    acceptAlert() {
      this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
        type = type == null ? "danger" : type;
        this.alert.type = type;
        this.alert.header = header;
        this.alert.body = body;
        this.alert.show = true;
        this.alert.options = options != null ? options : null;
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                this.isHeader = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.isFooter = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.isHeaderPrescription = data.filter((x) => x.State == '1' && x.Type == 'HeaderPrescription');
                this.isFooterPrescription = data.filter((x) => x.State == '1' && x.Type == 'FooterPrescription');
                this.Type = [
                    { name: "Encabezado", value: "Header", disabled: this.isHeader.length > 0 ? true : false },
                    { name: "Pie de Pagina", value: "Footer", disabled: this.isFooter.length > 0 ? true : false },
                    { name: "Encabezado Prescripción", value: "HeaderPrescription", disabled: this.isHeaderPrescription.length > 0 ? true : false },
                    { name: "Pie de Pagina Prescripción", value: "FooterPrescription", disabled: this.isFooterPrescription.length > 0 ? true : false },
                ];
            })
        },
        fetchCurrentTemp() {
            if (this.win.meta ? this.win.meta.editMood : this.editMood) {
                let { Id, Name, State, Body, Type } = JSON.parse(
                    JSON.stringify(this.win.meta ? this.win.meta.HF : this.HF)
                );
                this.editMood = true;
                this.form.Id = Id;
                this.form.Name = Name;
                this.form.Body = JSON.parse(Body);
                this.form.State = State === "Active" || State === 1 || State === "1" ? "1" : "0";
                this.form.ID_User = JSON.parse(localStorage.getItem("user")).id;
                this.form.Type = Type;
            } else {
                
                this.form.Id = "";
                this.form.Name = "";
                this.form.Body = {};
                this.form.State = "";
                this.form.ID_User = JSON.parse(localStorage.getItem("user")).id;
                this.form.Type = "";
            }
        },
        clean(){

            this.$emit("clean");
        },
        isEmpty(data) {
            return _.isEmpty(data);
        },
        reloadData(payload) {
            this.$emit("reloadData", payload);
        },
        open() {
            this.editMood
                ? (this.Body = JSON.parse(JSON.stringify(this.form.Body)))
                : "";

            this.dialog = true;
        },
        close() {
            this.Body = Object.assign({}, this.defaultTemp);
            this.dialog = false;
        },

        editorLoaded() {
            console.log("editorLoaded");
            this.editMood && this.$refs.emailEditor.editor.loadDesign(this.Body.design);
            // Pass your template JSON here
        },
        // called when the editor has finished loading
        editorReady() {
            console.log("editorReady");
        },
        exportData() {
            this.$refs.emailEditor.editor.exportHtml((data) => {
                this.form.Body = { design: data.design, html: data.html };
                console.log("exportHtml", data);
                this.close();
            });
        },
        async save() {
            this.$refs.observer.validate();
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.loading_btn = true;
                if (this.editMood) {
                    let obj = {
                        Id: this.form.Id,
                        Body: JSON.stringify({
                            html: this.form.Body.html,
                            design: this.form.Body.design,
                        }),
                        State: this.form.State,
                        Name: this.form.Name,
                        ID_User: this.form.ID_User,
                        Type: this.form.Type
                    };
                    this.$API.HF
                        .updateHF(obj)
                        .then((response) => {
                            this.loading_btn = false;
                            this.$refs.observer.reset();
                            this.showAlert("success", "Exito", "Se ha actualizado el template correctamente",'reloadandredirect');
                            console.log(response);
                        //    this.reloadData("email");
                        })
                        .catch((err) => {
                            this.loading_btn = false;
                            this.showAlert("danger", "Error", "Ha ocurrido un error al actualizar el template");
                            console.log(err);
                        });
                } else {
                    let obj = {
                        Body: JSON.stringify({
                            html: this.form.Body.html,
                            design: this.form.Body.design,
                        }),
                        State: this.form.State,
                        Name: this.form.Name,
                        ID_User: this.form.ID_User,
                        Type: this.form.Type,
                        ID_business : JSON.parse(localStorage.getItem("user")).businessid || "",
                        ID_branch : JSON.parse(localStorage.getItem("user")).branch || ""
                    };
                    this.$API.HF
                        .addHF(obj)
                        .then((response) => {
                            this.loading_btn = false;
                            this.$refs.observer.reset();
                            this.showAlert("success", "Exito", "Se ha creado el template correctamente",'reloadandredirect');
                            console.log(response);
                            this.reloadData("email");
                        })
                        .catch((err) => {
                            this.loading_btn = false;
                            this.showAlert("danger", "Error", "Ha ocurrido un error al crear el template");
                            console.log(err);
                        });
                }
            }
        },
    },
};
</script>


  
<style scoped>

.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}

.temp-dimensions {
    max-width: 100px;
    max-height: 300px;
}

.unlayer-editor {
    height: 1000px !important;
}

.unlayer-editor iframe {
    min-width: auto !important;
}
</style>
  
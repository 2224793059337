<template>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Agregar / Modificar encabezado y pie de página
        <template v-slot:actions>
          <v-icon color="primary" @click.native.stop="reloadp++">
            mdi-refresh
          </v-icon>
          <v-icon
            color="error"
            @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
          >
            mdi-close
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content >
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
  
            <v-tab href="#tab-1">
              Agregar / Modificar
              <v-icon>mdi-page-layout-header-footer</v-icon>
            </v-tab>
          </v-tabs>
  
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card class="white" flat>
                <v-card-text class="email-wrapper">
                  <v-card-text>
                    <ManageHeaderFooter :key="reloadp" :win="win" :HF="HF" :editMood="false"></ManageHeaderFooter>
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
  
  <script>
  import ManageHeaderFooter from "./ManageHeaderFooter.vue";
  
  export default {
    name: "AddHeaderFooter",
    components: {
        ManageHeaderFooter,
    },
    props: ["win"],
    data: () => ({
      HF: {

        Name: "",
        Body: {},
        Id: "",
        ID_User: "",
        State: "",
        Type: ""
      },
      tab: null,
      reloadp: 0,
    }),
    methods: {
      reloadData(payload) {
        this.$emit("reloadData", payload);
      },
    },
  };
  </script>
  
  <style scoped></style>
  